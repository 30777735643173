import React, { Component } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import moment from "moment";
import { Layout } from "antd";
import { getUser } from "../utils/token";

import "./App.scss";
import "antd/dist/antd.css";

import NotFound from "./NotFound";
import Forbidden from "./Forbidden";

import LayoutHeader from "./LayoutHeader";
import LayoutSider from "./LayoutSider";

import Login from "./Login";
import Attendance from "./Attendance";
import EmailTemplate from "./EmailTemplate";
import EventPage from "./EventPage";
import Home from "./Home";
import Overview from "./Overview";
import Registration from "./Registration";
import RegistrationField from "./RegistrationField";
import Ticket from "./Ticket";
import Scan from "./Scan";
import User from "./User";
import UserRole from "./UserRole";
import AuditLog from "./AuditLog";
import EmailLog from "./EmailLog";
import RaffleCollection from "./RaffleCollection";
import RafflePrize from "./RafflePrize";
import RaffleController from "./RaffleController";
import RaffleViewer from "./RaffleViewer";
import RaffleWinners from "./RaffleWinners";
import Passports from "./Passports";
import PassportClaims from "./PassportClaims";
import Payments from "./Payments";
import SalesReport from "./SalesReport";
import Profile from "./Profile";

moment.updateLocale(moment.locale(), { invalidDate: "N/A" });

const genericErrors = {
  401: {
    title: "Resource not Found",
    message: "Unable to load resource",
  },
  500: {
    title: "Internal Server Error",
    message: "The server was unable to complete your request.",
  },
  502: {
    title: "Bad Gateway",
    message: "The server was unable to complete your request.",
  },
  503: {
    title: "Service Unavailable",
    message: "The server was unable to complete your request.",
  },
};

const paths = [
  // {
  //   slug: "admin/reset/:token",
  //   route: "/admin/reset/:token",
  //   label: "Reset",
  //   component: Reset,
  //   display: [""],
  // },
  {
    route: "/403",
    label: "Forbidden",
    component: Forbidden,
  },
  {
    route: "/scan",
    label: "Scan",
    component: Scan,
  },
  {
    route: "/attendance",
    label: "Attendance",
    component: Attendance,
  },
  {
    route: "/email-template",
    label: "EmailTemplate",
    component: EmailTemplate,
  },
  {
    route: "/event-page",
    label: "EventPage",
    component: EventPage,
  },
  {
    route: "/home",
    label: "Dashboard",
    component: Home,
  },
  {
    route: "/admin",
    label: "Dashboard",
    component: Home,
  },
  {
    route: "/overview",
    label: "Overview",
    component: Overview,
  },
  {
    route: "/registration",
    label: "Registration",
    component: Registration,
  },
  {
    route: "/registration-field",
    label: "RegistrationField",
    component: RegistrationField,
  },
  {
    route: "/ticket",
    label: "Ticket",
    component: Ticket,
  },
  {
    route: "/login",
    label: "Login",
    component: Login,
  },
  {
    route: "/raffle-collection",
    label: "Collection",
    component: RaffleCollection,
  },
  {
    route: "/raffle-prizes",
    label: "Raffle Prizes",
    component: RafflePrize,
  },
  {
    route: "/raffle-controller",
    label: "Collection",
    component: RaffleController,
  },
  {
    route: "/raffle-winners",
    label: "Raffle Winners",
    component: RaffleWinners,
  },
  {
    route: "/passports",
    label: "Passports",
    component: Passports,
  },
  {
    route: "/passport-claims",
    label: "Passport Claims",
    component: PassportClaims,
  },
  {
    route: "/payments",
    label: "Payments",
    component: Payments,
  },
  {
    route: "/sales-report",
    label: "Sales Report",
    component: SalesReport,
  },
  {
    route: "/raffle-controller",
    label: "Collection",
    component: RaffleController,
  },
  {
    route: "/raffle-viewer",
    label: "Viewer",
    component: RaffleViewer,
  },
  {
    route: "/user",
    label: "User",
    component: User,
  },
  {
    route: "/user-role",
    label: "UserRole",
    component: UserRole,
  },
  {
    route: "/audit-log",
    label: "AuditLog",
    component: AuditLog,
  },
  {
    route: "/email-log",
    label: "EmailLog",
    component: EmailLog,
  },
  {
    route: "/profile",
    label: "Profile",
    component: Profile,
  },
  {
    route: "/",
    label: "Login",
    component: Login,
  },
];

const toastXHRError = (code) => {
  const error = genericErrors[code];
  if (error) {
    console.error(error.title, error.message);
  }
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      selectedEvent: {}
    };

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        switch (error.response.status) {
          case 401 /* signout() */:
            break;
          case 404:
            toastXHRError(404);
            break;
          case 500:
            toastXHRError(500);
            break;
          case 502:
            toastXHRError(502);
            break;
          case 503:
            toastXHRError(503);
            break;
          default:
            break;
        }
        return Promise.reject(error);
      }
    );

    axios.defaults.transformResponse = axios.defaults.transformResponse.concat();
  }

  handleSelectEvent = (event) => {
    this.setState({
      selectedEvent: event,
    });
  }

  render() {
    const { selectedEvent } = this.state;
    const user = getUser();
    const path = window.location.pathname

    if (path != "/login" && !user) {
      window.location.href = "/login";
      return;
    }

    const pageException = ["/403", "/404", "/login", "/profile"];
    const isPublic = ["/", "/login", "/raffle-viewer"].includes(path) || path.split('/')[1] === 'raffle-viewer'

    return (
      <Layout className="layout">
        <Layout.Content>

          <Layout className="sub-layout">
            {!isPublic ?
              <LayoutSider {...this.props} selectedEvent={selectedEvent} />
              : ''
            }
            <Layout.Content className="sub-layout-content">
              {!isPublic ?
                <LayoutHeader handleSelectEvent={this.handleSelectEvent} />
                : ''
              }
              <Switch className="sub-layout_content_switch">
                {window.scrollTo(0, 0)}
                {paths.map((path) => {
                  return (
                    <Route
                      key={path.route}
                      // exact={path.exact}
                      path={path.route}
                      component={path.component}
                      render={(props) => <path.component {...props} {...this.state} eventttt={selectedEvent} />}
                    />
                  );
                })}
                <Route component={NotFound} />
                <Route component={Forbidden} />
                <Redirect to="/404" />
              </Switch>
            </Layout.Content>
          </Layout>
        </Layout.Content >
      </Layout >
    );
  }
}

function mapStateToProps(state) {
  return {
    loggedIn: state.login.loggedIn,
    state: state,
  };
}

export default withRouter(connect(mapStateToProps, {})(App));
