import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "antd";
import { getUsers, createUser, updateUser, deleteUser } from "./actions";
import Page from "./Page";
import { getEvent } from "../../utils/token";

class UserCard extends Component {
  render() {
    return <Page {...this.props} />;
  }
}

const UserForm = Form.create({ name: "user-form" })(UserCard);

function mapStateToProps(state) {
  return {
    isLoading: state.user.isLoading,
    usersData: state.user.usersData,
    userData: state.user.userData,
    userRolesData: state.user.userRolesData,
    eventsData: state.header.eventsData,
    event: getEvent()
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onGetUsers: (data) => dispatch(getUsers(data)),
    onCreateUser: (data) => dispatch(createUser(data)),
    onUpdateUser: (data) => dispatch(updateUser(data)),
    onDeleteUser: (data) => dispatch(deleteUser(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
