import React, { Component } from "react";
import { Form, Modal, message, Layout, Button, Row, Col, Input, Spin, Select, Icon, Tooltip, Tabs } from "antd";
import "./index.scss";
import { notify, notifyFormError } from "../../../shared/Notification";
import ImageUploader from "../../../shared/ImageUploader";
import { EditorState, convertToRaw, ContentState, Modifier } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const { Content } = Layout;
const { TabPane } = Tabs;

const html = '';
const contentBlock = htmlToDraft(html);
const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
const editorState = EditorState.createWithContent(contentState);

class TicketModal extends Component {
  initialState = {
    showModal: false,
    settingsData: {},
    imagePath: '',
    editorContent: {
      blank_page: "",
      splash_page: "",
      prize_page: "",
      winner_page: "",
      thank_you_page: "",
    },
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.blankBackgroundImage = React.createRef();
    this.blankTextEditor = React.createRef();
    this.splashBackgroundImage = React.createRef();
    this.splashTextEditor = React.createRef();
    this.prizeBackgroundImage = React.createRef();
    this.prizeTextEditor = React.createRef();
    this.winnerBackgroundImage = React.createRef();
    this.winnerTextEditor = React.createRef();
    this.thankYouBackgroundImage = React.createRef();
    this.thankYouTextEditor = React.createRef();
  }

  handleCloseModal = () => {
    this.setState({
      showModal: false,
      selectedRow: {}
    });
    this.props.form.resetFields();
    this.props.handleCloseModal();
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.showModal !== nextProps.showModal) {
      this.setState({
        showModal: nextProps.showModal,
        imagePath: "uploads/events/" + nextProps.event.id + "/raffle-page"
      });

    }

    if (this.props.settingsData !== nextProps.settingsData) {
      const settings = {};
      nextProps.settingsData.forEach(row => {
        switch (row.name) {
          case 'blank_page':
            settings.blank_page = row.value;
            if (settings.blank_page.content && settings.blank_page.content.trim() != "") {
              const block = htmlToDraft(settings.blank_page.content);
              if (block) {
                const state = ContentState.createFromBlockArray(block.contentBlocks);
                const textState = EditorState.createWithContent(state);
                settings.blank_page.content = textState
              }
            }
            break;

          case 'splash_page':
            settings.splash_page = row.value;
            if (settings.splash_page.content && settings.splash_page.content.trim() != "") {
              const block = htmlToDraft(settings.splash_page.content);
              if (block) {
                const state = ContentState.createFromBlockArray(block.contentBlocks);
                const textState = EditorState.createWithContent(state);
                settings.splash_page.content = textState
              }
            }
            break;

          case 'prize_page':
            settings.prize_page = row.value;
            if (settings.prize_page.content && settings.prize_page.content.trim() != "") {
              const block = htmlToDraft(settings.prize_page.content);
              if (block) {
                const state = ContentState.createFromBlockArray(block.contentBlocks);
                const textState = EditorState.createWithContent(state);
                settings.prize_page.content = textState
              }
            }
            break;

          case 'winner_page':
            settings.winner_page = row.value;
            if (settings.winner_page.content && settings.winner_page.content.trim() != "") {
              const block = htmlToDraft(settings.winner_page.content);
              if (block) {
                const state = ContentState.createFromBlockArray(block.contentBlocks);
                const textState = EditorState.createWithContent(state);
                settings.winner_page.content = textState
              }
            }
            break;

          case 'thank_you_page':
            settings.thank_you_page = row.value;
            if (settings.thank_you_page.content && settings.thank_you_page.content.trim() != "") {
              const block = htmlToDraft(settings.thank_you_page.content);
              if (block) {
                const state = ContentState.createFromBlockArray(block.contentBlocks);
                const textState = EditorState.createWithContent(state);
                settings.thank_you_page.content = textState
              }
            }
            break;
        }
      });

      this.setState({ settingsData: settings });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { selectedRow, settingsData } = this.state;

    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const blankBackgroundImage = this.blankBackgroundImage.current ? await this.blankBackgroundImage.current.handleUploadRequest() : null;
        const splashBackgroundImage = this.splashBackgroundImage.current ? await this.splashBackgroundImage.current.handleUploadRequest() : null;
        const prizeBackgroundImage = this.prizeBackgroundImage.current ? await this.prizeBackgroundImage.current.handleUploadRequest() : null;
        const winnerBackgroundImage = this.winnerBackgroundImage.current ? await this.winnerBackgroundImage.current.handleUploadRequest() : null;
        const thankYouBackgroundImage = this.thankYouBackgroundImage.current ? await this.thankYouBackgroundImage.current.handleUploadRequest() : null;

        const settings = [
          {
            key: "blank_page",
            value: {
              background_color: values.blank_page.background_color,
              background_image: blankBackgroundImage ? blankBackgroundImage.url : settingsData.blank_page.background_image,
              content: settingsData.blank_page.content ? draftToHtml(convertToRaw(settingsData.blank_page.content.getCurrentContent())) : ''
            }
          },
          {
            key: "splash_page",
            value: {
              background_color: values.splash_page.background_color,
              background_image: splashBackgroundImage ? splashBackgroundImage.url : settingsData.splash_page.background_image,
              content: settingsData.splash_page.content ? draftToHtml(convertToRaw(settingsData.splash_page.content.getCurrentContent())) : ''
            }
          },
          {
            key: "prize_page",
            value: {
              background_color: values.prize_page.background_color,
              background_image: prizeBackgroundImage ? prizeBackgroundImage.url : settingsData.prize_page.background_image,
              content: settingsData.prize_page.content ? draftToHtml(convertToRaw(settingsData.prize_page.content.getCurrentContent())) : ''
            }
          },
          {
            key: "winner_page",
            value: {
              background_color: values.winner_page.background_color,
              background_image: winnerBackgroundImage ? winnerBackgroundImage.url : settingsData.winner_page.background_image,
              content: settingsData.winner_page.content ? draftToHtml(convertToRaw(settingsData.winner_page.content.getCurrentContent())) : ''
            }
          },
          {
            key: "thank_you_page",
            value: {
              background_color: values.thank_you_page.background_color,
              background_image: thankYouBackgroundImage ? thankYouBackgroundImage.url : settingsData.thank_you_page.background_image,
              content: settingsData.thank_you_page.content ? draftToHtml(convertToRaw(settingsData.thank_you_page.content.getCurrentContent())) : ''
            }
          },
        ];

        const params = {
          event_id: this.props.event.id,
          settings: settings
        };

        await this.props
          .onBulkUpdateSettings(params)
          .then((response) => {
            if (response.response.data.status === "success") {
              notify('success', 'Updated raffle settings');
              this.handleCloseModal();
            } else {
              notify('error', response.response.data.message);
            }
          })
          .catch(error => {
            notify('error', error.message);
            console.log("error", error);
          });
      } else {
        notifyFormError(err);
        console.log("err", err);
      }
    });
  }

  handleEditorChange = (value, key) => {
    const settingsData = this.state.settingsData;
    settingsData[key].content = value;

    this.setState({
      settingsData
    });
  }

  render() {
    const { showModal, settingsData, imagePath, editorContent } = this.state;
    const { isLoading, form: { getFieldDecorator }, match } = this.props;

    // const settingsType = [
    //   {
    //     key: "blank_page",
    //     tab_name: "Blank",
    //     prefix: "blank.",
    //     image_uploader: this.blankBackgroundImage,
    //     text_editor: this.blankTextEditor
    //   },
    //   {
    //     key: "splash_page",
    //     tab_name: "Splash",
    //     prefix: "splash.",
    //     image_uploader: this.splashBackgroundImage,
    //     text_editor: this.splashTextEditor
    //   },
    //   {
    //     key: "prize_page",
    //     tab_name: "Prize",
    //     prefix: "prize.",
    //     image_uploader: this.prizeBackgroundImage,
    //     text_editor: this.prizeTextEditor
    //   },
    //   {
    //     key: "winner_page",
    //     tab_name: "Winner",
    //     prefix: "winner.",
    //     image_uploader: this.winnerBackgroundImage,
    //     text_editor: this.winnerTextEditor
    //   },
    //   {
    //     key: "thank_you_page",
    //     tab_name: "Thank You",
    //     prefix: "thank_you.",
    //     image_uploader: this.thankYouBackgroundImage,
    //     text_editor: this.thankYouTextEditor
    //   },
    // ];
    const sample = "sample";
    return (
      <Modal
        title={'Raffle Settings'}
        visible={showModal}
        onCancel={this.handleCloseModal}
        centered={true}
        footer={null}
        closable={false}
        maskClosable={false}
        width={"70%"}
      >
        <Layout className="overview-container">
          <Content className="overview-content" style={{ padding: "10px" }}>
            <Form onSubmit={this.handleSubmit} style={{ width: "100%" }} disabled={isLoading}>
              <Row type="flex" gutter={30}>
                <Col span={24}>
                  <Tabs>
                    <TabPane tab="Blank" key="blank">
                      <Row type="flex" gutter={30}>
                        <Col span={12}>
                          <Form.Item label="Background Image  (Recommended resolution: 1920 x 1080)">
                            <ImageUploader
                              match={match}
                              ref={this.blankBackgroundImage}
                              fileFolder={imagePath}
                              buttonDesc="Click here to select file to be uploaded"
                              imageUrl={settingsData.blank_page && settingsData.blank_page.background_image ? settingsData.blank_page.background_image : null}
                              filename={settingsData.blank_page && settingsData.blank_page.background_image ? settingsData.blank_page.background_image : ''}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item label="Background Color">
                            {getFieldDecorator("blank_page.background_color", {
                              rules: [],
                              initialValue: settingsData.blank_page && settingsData.blank_page.background_color ? settingsData.blank_page.background_color : ""
                            })(<Input type="color" />)}
                          </Form.Item>
                        </Col>

                        <Col span={24}>
                          <Form.Item label="">
                            <Editor
                              ref={this.blankTextEditor}
                              editorState={settingsData.blank_page && settingsData.blank_page ? settingsData.blank_page.content : ''}
                              wrapperClassName="wysiwyg-editor"
                              editorClassName="wysiwyg-editor-textarea"
                              onEditorStateChange={e => this.handleEditorChange(e, "blank_page")}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tab="Splash" key="splash">
                      <Row type="flex" gutter={30}>
                        <Col span={12}>
                          <Form.Item label="Background Image (Recommended resolution: 1920 x 1080)">
                            <ImageUploader
                              match={match}
                              ref={this.splashBackgroundImage}
                              fileFolder={imagePath}
                              buttonDesc="Click here to select file to be uploaded"
                              imageUrl={settingsData.splash_page && settingsData.splash_page.background_image ? settingsData.splash_page.background_image : null}
                              filename={settingsData.splash_page && settingsData.splash_page.background_image ? settingsData.splash_page.background_image : ''}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item label="Background Color">
                            {getFieldDecorator("splash_page.background_color", {
                              rules: [],
                              initialValue: settingsData.splash_page && settingsData.splash_page.background_color ? settingsData.splash_page.background_color : ""
                            })(<Input type="color" />)}
                          </Form.Item>
                        </Col>

                        <Col span={24}>
                          <Form.Item label="">
                            <Editor
                              ref={this.splashTextEditor}
                              editorState={settingsData.splash_page && settingsData.splash_page ? settingsData.splash_page.content : ''}
                              wrapperClassName="wysiwyg-editor"
                              editorClassName="wysiwyg-editor-textarea"
                              onEditorStateChange={e => this.handleEditorChange(e, "splash_page")}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tab="Prize" key="prize">
                      <Row type="flex" gutter={30}>
                        <Col span={12}>
                          <Form.Item label="Background Image (Recommended resolution: 1920 x 1080)">
                            <ImageUploader
                              match={match}
                              ref={this.prizeBackgroundImage}
                              fileFolder={imagePath}
                              buttonDesc="Click here to select file to be uploaded"
                              imageUrl={settingsData.prize_page && settingsData.prize_page.background_image ? settingsData.prize_page.background_image : null}
                              filename={settingsData.prize_page && settingsData.prize_page.background_image ? settingsData.prize_page.background_image : ''}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item label="Background Color">
                            {getFieldDecorator("prize_page.background_color", {
                              rules: [],
                              initialValue: settingsData.prize_page && settingsData.prize_page.background_color ? settingsData.prize_page.background_color : ""
                            })(<Input type="color" />)}
                          </Form.Item>
                        </Col>

                        <Col span={24}>
                          <Form.Item label="">
                            <Editor
                              ref={this.prizeTextEditor}
                              editorState={settingsData.prize_page && settingsData.prize_page ? settingsData.prize_page.content : ''}
                              wrapperClassName="wysiwyg-editor"
                              editorClassName="wysiwyg-editor-textarea"
                              onEditorStateChange={e => this.handleEditorChange(e, "prize_page")}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tab="Winner" key="winner">
                      <Row type="flex" gutter={30}>
                        <Col span={12}>
                          <Form.Item label="Background Image  (Recommended resolution: 1920 x 1080)">
                            <ImageUploader
                              match={match}
                              ref={this.prizeBackgroundImage}
                              fileFolder={imagePath}
                              buttonDesc="Click here to select file to be uploaded"
                              imageUrl={settingsData.winner_page && settingsData.winner_page.background_image ? settingsData.winner_page.background_image : null}
                              filename={settingsData.winner_page && settingsData.winner_page.background_image ? settingsData.winner_page.background_image : ''}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item label="Background Color">
                            {getFieldDecorator("winner_page.background_color", {
                              rules: [],
                              initialValue: settingsData.winner_page && settingsData.winner_page.background_color ? settingsData.winner_page.background_color : ""
                            })(<Input type="color" />)}
                          </Form.Item>
                        </Col>

                        <Col span={24}>
                          <Form.Item label="">
                            <Editor
                              ref={this.winnerTextEditor}
                              editorState={settingsData.winner_page && settingsData.winner_page ? settingsData.winner_page.content : ''}
                              wrapperClassName="wysiwyg-editor"
                              editorClassName="wysiwyg-editor-textarea"
                              onEditorStateChange={e => this.handleEditorChange(e, "winner_page")}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tab="Thank You" key="thank_you">
                      <Row type="flex" gutter={30}>
                        <Col span={12}>
                          <Form.Item label="Background Image (Recommended resolution: 1920 x 1080)">
                            <ImageUploader
                              match={match}
                              ref={this.thankYouBackgroundImage}
                              fileFolder={imagePath}
                              buttonDesc="Click here to select file to be uploaded"
                              imageUrl={settingsData.thank_you_page && settingsData.thank_you_page.background_image ? settingsData.thank_you_page.background_image : null}
                              filename={settingsData.thank_you_page && settingsData.thank_you_page.background_image ? settingsData.thank_you_page.background_image : ''}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item label="Background Color">
                            {getFieldDecorator("thank_you_page.background_color", {
                              rules: [],
                              initialValue: settingsData.thank_you_page && settingsData.thank_you_page.background_color ? settingsData.thank_you_page.background_color : ""
                            })(<Input type="color" />)}
                          </Form.Item>
                        </Col>

                        <Col span={24}>
                          <Form.Item label="">
                            <Editor
                              ref={this.thankYouTextEditor}
                              editorState={settingsData.thank_you_page && settingsData.thank_you_page ? settingsData.thank_you_page.content : ''}
                              wrapperClassName="wysiwyg-editor"
                              editorClassName="wysiwyg-editor-textarea"
                              onEditorStateChange={e => this.handleEditorChange(e, "thank_you_page")}
                              toolbarCustomButtons={[<CustomOption />]}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </TabPane>
                  </Tabs>
                </Col>
              </Row>

              <Row type="flex" gutter={30}>
                <Col span={24} align="right">
                  <Form.Item>
                    <Button
                      type="default"
                      disabled={isLoading}
                      loading={isLoading}
                      onClick={this.handleCloseModal}
                    >
                      <Icon type="close" />
                      Close
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={isLoading}
                      loading={isLoading}
                    >
                      <Icon type="save" />
                      Save
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Content>
        </Layout >
      </Modal >
    );
  }
}

const defaultTags = [
  {
    value: 'prize-name',
    label: 'Prize Name'
  },
  {
    value: 'category-category',
    label: 'Category'
  },
  {
    value: 'winner',
    label: 'Winner'
  },
  {
    value: 'prize-image',
    label: 'prize-image'
  },
  {
    value: 'prize-description',
    label: 'prize-description'
  }
];

class CustomOption extends Component {
  initialState = {
    selectedTag: '',
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  handleAddTag = () => {
    const { editorState, onChange } = this.props;
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      "{{" + this.state.selectedTag + "}}", //'⭐',
      editorState.getCurrentInlineStyle(),
    );
    onChange(EditorState.push(editorState, contentState, 'insert-characters'));
  };

  handleSelectTag = value => {
    this.setState({ selectedTag: value });
  }

  render() {
    const tags = defaultTags;
    tags.sort((a, b) => {
      let la = a.label.toLowerCase(),
        lb = b.label.toLowerCase();

      if (la < lb) {
        return -1;
      }
      if (la > lb) {
        return 1;
      }
      return 0;
    });

    return (
      <div style={{ display: "flex", margin: "0 10px" }}>
        <Select onChange={this.handleSelectTag} style={{ width: "150px" }}>
          {tags.map((row, index) => (
            <Select.Option value={row.value} key={index}>{row.label}</Select.Option>
          ))}
        </Select>
        <Button onClick={this.handleAddTag} style={{ marginLeft: "5px" }}><Icon type="plus" />Add Tag</Button>
      </div>
    );
  }
}

const TicketModalComponent = Form.create({ name: "ticket-modal" })(TicketModal);

export default TicketModalComponent;
