import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "antd";
import { getControllerSetup, getActivePrizes, getActiveCollections, createRaffleDraw, getRaffleDraws, updateRaffleViewer, updateRaffleDraw, bulkUpdateSettings } from "./actions"
import { getEvent } from "../../utils/token";

import Page from "./Page";

class RaffleControllerCard extends Component {
  render() {
    return <Page {...this.props} />;
  }
}

const RaffleControllerForm = Form.create({ name: "raffle-collection-card" })(RaffleControllerCard);

function mapStateToProps(state) {
  return {
    activePrizesData: state.rcontroller.activePrizesData,
    activeCollectionData: state.rcontroller.activeCollectionData,
    raffleDrawData: state.rcontroller.raffleDrawData,
    raffleDrawList: state.rcontroller.raffleDrawList,
    putViewerData: state.rcontroller.putViewerData,
    settingsData: state.rcontroller.settingsData,
    prizesData: state.rcontroller.prizesData,
    collectionsData: state.rcontroller.collectionsData,
    event: getEvent()
  };
}

function mapDispatchToProps(dispatch) {
  return {
    OnGetControllerSetup: (data) => dispatch(getControllerSetup(data)),
    OnGetActivePrizes: (data) => dispatch(getActivePrizes(data)),
    OnGetActiveCollections: (data) => dispatch(getActiveCollections(data)),
    OnCreateRaffleDraw: (data) => dispatch(createRaffleDraw(data)),
    OnGetRaffleDraws: (data) => dispatch(getRaffleDraws(data)),
    OnUpdateRaffleViewer: (data) => dispatch(updateRaffleViewer(data)),
    onUpdateRaffleDraw: (data) => dispatch(updateRaffleDraw(data)),
    onBulkUpdateSettings: (data) => dispatch(bulkUpdateSettings(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RaffleControllerForm);
