import Axios from "../../../utils/axios";

export const getUsers = (data) => {
    return (dispatch) => {
        Axios.get("/users", dispatch, "GET_USERS", data);
    };
};

export const createUser = (data) => {
    return (dispatch) => {
        return Axios
            .post("/user", dispatch, "CREATE_USER", data)
            .then((response) => {
                if (response.response.data.status === "success") {
                    const param = {
                        event_id: response.response.data.data.event_id
                    };
                    dispatch(getUsers(param));
                }
                return response;
            });
    };
};

export const updateUser = (data) => {
    return (dispatch) => {
        return Axios
            .post("/user/" + data.id, dispatch, "UPDATE_USER", data)
            .then((response) => {
                if (response.response.data.status === "success") {
                    const param = {
                        event_id: response.response.data.data.event_id
                    };
                    dispatch(getUsers(param));
                }
                return response;
            });
    };
};

export const deleteUser = (data) => {
    return (dispatch) => {
        return Axios
            .delete("/user/" + data.id, dispatch, "DELETE_USER", data)
            .then((response) => {
                if (response.response.data.status === "success") {
                    const param = {
                        event_id: data.event_id
                    };
                    dispatch(getUsers(param));
                }
                return response;
            });
    };
};