import React, { Component } from "react";
import { Form, Layout, Button, Row, Col, Popconfirm, Table, Icon, Tooltip } from "antd";
import "./index.scss";
import CreateUpdateModal from "./components/CreateUpdateModal";
import { checkPermission } from "../../utils/helper";
import { Redirect } from "react-router-dom";
import PageHeader from "../shared/PageHeader";
import { notify } from "../shared/Notification";

const { Content } = Layout;

class RegistrationField extends Component {
  initialState = {
    registrationFieldsData: [],
    showCreateUpdateModal: false,
    selectedRow: {}
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  handleCreateButton = () => {
    this.setState({
      showCreateUpdateModal: true
    });
  };

  handleUpdateButton = (row) => {
    this.setState({
      showCreateUpdateModal: true,
      selectedRow: row
    });
  };

  handleDeleteButton = async (row) => {
    await this.props.onDeleteRegistrationField(row);
    notify('success', 'Deleted Registration Field: ' + row.label);
  }

  handleCloseModal = () => {
    this.setState({
      showCreateUpdateModal: false,
    });
  };

  componentDidMount() {
    const onGetParam = {
      event_id: this.props.event.id
    };
    this.props.onGetRegistrationFields(onGetParam);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.registrationFieldsData !== nextProps.registrationFieldsData) {
      this.setState({ registrationFieldsData: nextProps.registrationFieldsData });
    }
  }

  render() {
    const { registrationFieldsData, showCreateUpdateModal, selectedRow } = this.state;
    const { isLoading, } = this.props;
    const module = "registration-field";

    if (!checkPermission(module, "view")) {
      return (<Redirect to="/403" />);
    }

    const columns = [
      {
        title: 'Field Name',
        dataIndex: 'label',
        key: 'label',
      },
      {
        title: 'Slug',
        dataIndex: 'query_slug',
        key: 'query_slug',
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        align: 'center',
      },
      {
        title: 'Is Required',
        dataIndex: 'is_required',
        key: 'is_required',
        align: 'center',
        render: is_required => (
          <span>
            {is_required ? 'Yes' : 'No'}
          </span>
        ),
      },
      {
        title: 'Order',
        dataIndex: 'ordinality',
        key: 'ordinality',
        align: 'center',
      },
      {
        title: 'Action',
        key: 'action',
        align: 'center',
        render: (text, record) => (
          <span>
            {(checkPermission(module, "edit")) ?
              <Tooltip title="Edit">
                <Button type="default" onClick={() => this.handleUpdateButton(record)}><Icon type="edit" /></Button>
              </Tooltip> : ''
            }
            &nbsp;&nbsp;
            {/* <Divider type="vertical" /> */}
            {(checkPermission(module, "delete")) ?
              <Popconfirm
                title="Are you sure you want to delete?"
                onConfirm={() => this.handleDeleteButton(record)}
                okText="Proceed"
                cancelText="Cancel"
                disabled={!record.is_deletable}
              >
                <Tooltip title="Delete">
                  <Button type="danger" disabled={!record.is_deletable}><Icon type="delete" /></Button>
                </Tooltip>
              </Popconfirm> : ''
            }
          </span>
        ),
      },
    ];

    return (
      <Layout className="registration-field-container">
        <Content className="registration-field-content" style={{ padding: "20px 60px" }}>
          <PageHeader rightPanel={(checkPermission(module, "create")) ? <Button type="primary" loading={isLoading} onClick={this.handleCreateButton}><Icon type="plus" />Add Field</Button> : ''} />

          <Row type="flex" gutter={30}>
            <Col span={24}>
              <Table columns={columns} dataSource={registrationFieldsData} loading={isLoading} rowKey={"id"} />
            </Col>
          </Row>
        </Content>

        <CreateUpdateModal showModal={showCreateUpdateModal} selectedRow={selectedRow} handleCloseModal={this.handleCloseModal} {...this.props} />
      </Layout>
    );
  }
}

const RegistrationFieldForm = Form.create({ name: "registration-field-form" })(RegistrationField);

export default RegistrationFieldForm;
