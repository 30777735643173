import React, { Component, PureComponent, useRef } from "react";
import { Form, Layout, Col, Row, Tabs, Spin, Statistic, Button, Icon } from "antd";
import "./index.scss";
import { PieChart, Pie, Cell, ResponsiveContainer, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import html2canvas from "html2canvas";
import { setEvent, getUser } from "../../utils/token";
import { checkPermission } from "../../utils/helper";
import { Redirect } from "react-router-dom";
import PageHeader from "../shared/PageHeader";
import { jsPDF } from "jspdf";

const COLORS = ["#7eb0d5", "#fd7f6f", "#b2e061", "#bd7ebe", "#ffb55a", "#ffee65", "#beb9db", "#fdcce5", "#8bd3c7"];

const { Content } = Layout;

class Appointment extends Component {
  initialState = {
    statisticsData: [],
    eventsData: []
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.exportRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.event) {
      const onGetParam = {
        event_id: this.props.event.id,
      };
      this.props.onGetStatistics(onGetParam);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.statisticsData !== nextProps.statisticsData) {
      this.setState({ statisticsData: nextProps.statisticsData });
    }

    if (this.props.eventsData !== nextProps.eventsData) {
      this.setState({ eventsData: nextProps.eventsData });
    }
  }

  renderCustomLabel = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, outerRadius, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius) * cos;
    const sy = cy + (outerRadius) * sin;
    const mx = cx + (outerRadius + 15) * cos;
    const my = cy + (outerRadius + 15) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 5;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${payload.name}`}</text>
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={12} textAnchor={textAnchor} fill="#999">
          {`${value} (${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };

  handleDownload = async () => {
    const input = document.getElementById('dashboard-container');
    await html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF("p", "mm", "a4");
        const width = pdf.internal.pageSize.getWidth();
        pdf.addImage(imgData, 'JPEG', 4, 30, width, 120);
        pdf.save("dashboard.pdf");
      });
  }

  exportAsImage = async (element, imageFileName) => {
    const html = document.getElementsByTagName("html")[0];
    const body = document.getElementsByTagName("body")[0];
    let htmlWidth = html.clientWidth;
    let bodyWidth = body.clientWidth;

    const newWidth = element.scrollWidth - element.clientWidth;

    if (newWidth > element.clientWidth) {
      htmlWidth += newWidth;
      bodyWidth += newWidth;
    }

    html.style.width = htmlWidth + "px";
    body.style.width = bodyWidth + "px";

    const canvas = await html2canvas(element);
    const image = canvas.toDataURL("image/png", 1.0);
    this.downloadImage(image, imageFileName);
    html.style.width = null;
    body.style.width = null;
  };

  downloadImage = (blob, fileName) => {
    const fakeLink = window.document.createElement("a");
    fakeLink.style = "display:none;";
    fakeLink.download = fileName;

    fakeLink.href = blob;

    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);

    fakeLink.remove();
  };

  handleSelectEvent = row => {
    this.setState({
      selectedEvent: row
    });
    setEvent(row);
    window.location.reload();
  }

  render() {
    const { statisticsData, eventsData } = this.state;
    const { isLoading, isEventLoading } = this.props;
    const user = JSON.parse(getUser());
    const events = user && user.events;
    const module = "dashboard";

    // if (!checkPermission(module, "view")) {
    //   return (<Redirect to="/403" />);
    // }

    if (this.props.event == null) {

      // if (eventsData.length == 0) {
      //   return ('');
      // }

      return (
        <Layout className="registration-container" >
          <Content className="registration-content" style={{ padding: "20px 60px" }}>
            <Spin spinning={isEventLoading} key='home-spin'>
              <Row type="flex" gutter={30}>
                {eventsData.map((event) => {
                  if (events.indexOf(event.id) > -1 || user.is_super_admin == 1) {
                    return (
                      <Col span={8} style={{ padding: "10px" }} key={event.id}>
                        <Row className="event-holder" onClick={() => this.handleSelectEvent(event)}>
                          <Col span={24} style={{ minHeight: "150px", maxHeight: "150px", textAlign: "center", position: "relative" }}>
                            {event && event.page_details && event.page_details.cover_photo ? <img src={process.env.REACT_APP_S3_BUCKET + event.page_details.cover_photo} height="150px" style={{ maxWidth: "100%" }} /> : null}
                          </Col>

                          <Col span={24} style={{ fontWeight: "bold", textAlign: "center", fontSize: "1.1rem" }}>
                            {event.event_name}
                          </Col>
                        </Row>
                      </Col>
                    );
                  }
                })}
              </Row>
            </Spin>
          </Content>
        </Layout>
      );
    }

    // return (
    //   <Layout className="registration-container" >
    //     <Content className="registration-content" style={{ padding: "20px 60px" }}>
    //       <PageHeader rightPanel={<Button type="primary" onClick={() => this.exportAsImage(this.exportRef.current, "Dashboard")}>
    //         <Icon type="download" />
    //         Download
    //       </Button>} />
    //     </Content>
    //   </Layout>
    // );

    let registerTicketData = [];
    if (statisticsData.register_ticket) {
      statisticsData.register_ticket.forEach(row => {
        registerTicketData.push({
          name: row.ticket.ticket_name,
          value: row.total
        })
      });
    }

    let registerStatusData = [];
    if (statisticsData.register_status) {
      statisticsData.register_status.forEach(row => {
        let status = "";
        switch (row.status) {
          case 0:
            status = "Declined";
            break;

          case 1:
            status = "For Approval";
            break;

          case 2:
            status = "Approved";
            break;

          default:
            status = "For Approval";
            break;
        }

        registerStatusData.push({
          name: status,
          value: row.total
        })
      });
    }

    let attendanceTicketData = [];
    let tickets = [];
    if (statisticsData.attendance_ticket) {
      statisticsData.attendance_ticket.forEach(row => {
        let hasMatch = false;
        attendanceTicketData.forEach((dataRow, index) => {
          if (dataRow.name === row.timekey) {
            hasMatch = true;
            dataRow[row.ticket.ticket_name] = row.total
          }
        });

        if (!hasMatch) {
          let pushData = { name: row.timekey };
          pushData[row.ticket.ticket_name] = row.total;
          attendanceTicketData.push(pushData);
        }

        if (tickets.indexOf(row.ticket.ticket_name) == -1) {
          tickets.push(row.ticket.ticket_name);
        }
      });
    }

    return (
      <Layout className="registration-container" >
        <Content className="registration-content" style={{ padding: "20px 60px" }}>
          <PageHeader rightPanel={<Button type="primary" onClick={this.handleDownload}>
            <Icon type="download" />
            Download
          </Button>} />

          <div id="dashboard-container" ref={this.exportRef}>
            <Row type="flex" gutter={30}>
              <Col span={6}>
                <Spin spinning={isLoading} key="registered-spin">
                  <Statistic title="Registered Users" value={statisticsData.register} precision={0} />
                </Spin>

                {registerStatusData.map(row => (
                  <Spin key={row.name} spinning={isLoading}>
                    <Statistic title={`${row.name} Status`} value={row.value} suffix={`/${statisticsData.register}`} precision={0} />
                  </Spin>
                ))}
              </Col>

              <Col span={12}>
                <Spin spinning={isLoading} key="attendance-spin" style={{ textAlign: "center" }}>
                  <PieChart width={600} height={400}>
                    <Pie
                      data={registerTicketData}
                      cx="50%"
                      cy="50%"
                      outerRadius={150}
                      fill="#8884d8"
                      dataKey="value"
                      labelLine={false}
                      label={this.renderCustomLabel}
                    >
                      {registerTicketData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                  </PieChart>
                </Spin>
              </Col>

              <Col span={6}>
                <Spin spinning={isLoading} key="attendance-spin">
                  <Statistic title={`Email Blasting ${statisticsData.blast == statisticsData.total_blast ? '' : 'Ongoing'}`} value={statisticsData.blast ?? 0} suffix={`/${statisticsData.total_blast ?? 0}`} precision={0} />
                </Spin>

                <Spin spinning={isLoading} key="register-qr-spin">
                  <Statistic title="QR Sent" value={statisticsData.register_qr} suffix={`/${statisticsData.register ?? 0}`} precision={0} />
                </Spin>

                <Spin spinning={isLoading} key="register-reminder-spin">
                  <Statistic title="Reminder Sent" value={statisticsData.register_reminder} suffix={`/${statisticsData.register ?? 0}`} precision={0} />
                </Spin>
              </Col>
            </Row>

            <Row type="flex" gutter={30}>
              <Col span={6}>
                <Spin spinning={isLoading} key="attendance-spin">
                  <Statistic title="Attendees" value={statisticsData.attendance} suffix={`(${(((statisticsData.attendance ?? 0) / (statisticsData.register ?? 1)) * 100).toFixed(2)}%)`} precision={0} />
                </Spin>
              </Col>

              <Col span={12}>
                <AreaChart
                  width={500}
                  height={300}
                  data={attendanceTicketData}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Legend />
                  <Tooltip />
                  {tickets.map((ticket, index) => (
                    <Area key={`cell-${index}`} type="monotone" dataKey={ticket} stackId="1" stroke={COLORS[index % COLORS.length]} fill={COLORS[index % COLORS.length]} />
                  ))}
                </AreaChart>
              </Col>
            </Row>
          </div>
        </Content>
      </Layout>
    );
  }
}

const AppointmentForm = Form.create({ name: "appointment_form" })(Appointment);

export default AppointmentForm;
