const initialData = {
  isLoading: false,
  usersData: [],
  userData: {},
  userRolesData: [],
};

export default function admin(state = initialData, action) {
  switch (action.type) {
    case "GET_USERS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_USERS_SUCCESS":
      return {
        ...state,
        usersData: action.payload.data,
        userRolesData: action.payload.user_role,
        isLoading: false,
      };
    case "GET_USERS_FAILED":
      return {
        ...state,
        usersData: [],
        isLoading: false,
      };

    case "CREATE_USER_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "CREATE_USER_SUCCESS":
      return {
        ...state,
        userData: action.payload.data,
        isLoading: false,
      };
    case "CREATE_USER_FAILED":
      return {
        ...state,
        userData: {},
        isLoading: false,
      };

    case "UPDATE_USER_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "UPDATE_USER_SUCCESS":
      return {
        ...state,
        userData: action.payload.data,
        isLoading: false,
      };
    case "UPDATE_USER_FAILED":
      return {
        ...state,
        userData: {},
        isLoading: false,
      };

    case "DELETE_USER_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "DELETE_USER_SUCCESS":
      return {
        ...state,
        userData: action.payload.data,
        isLoading: false,
      };
    case "DELETE_USER_FAILED":
      return {
        ...state,
        userData: {},
        isLoading: false,
      };

    default:
      return {
        ...state,
      };
  }
}
