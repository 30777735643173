import React, { Component } from "react";
import { Form, Layout, Select, Divider, Menu, Icon, Spin, Row, Col, Button } from "antd";
import { Link } from "react-router-dom";
import "./index.scss";
import { getUser, setEvent, getEvent } from "../../utils/token";
import { checkPermission } from "../../utils/helper";
import { setMenu, getMenu } from "../../utils/storage";
import { Redirect } from "react-router-dom";
import Logo from "../shared/Logo";

const { Header } = Layout;
const { Option } = Select;

class LayoutSider extends Component {
  initialState = {
    sider: [
      {
        item_group: false,
        name: "Dashboard",
        key: "dashboard",
        link: "/",
        icon: "dashboard",
      },
      {
        item_group: true,
        name: "Events",
        key: "event",
        icon: "calendar",
        items: [
          {
            item_group: false,
            name: "Overview",
            key: "overview",
            link: "/overview",
            icon: "setting",
          },
          {
            item_group: false,
            name: "Email Template",
            key: "email-template",
            link: "/email-template",
            icon: "mail",
          },
          {
            item_group: false,
            name: "Event Page",
            key: "event-page",
            link: "/event-page",
            icon: "layout",
          },
          {
            item_group: false,
            name: "Passports",
            key: "passports",
            link: "/passports",
            icon: "book",
          },
          {
            item_group: false,
            name: "Registration Fields",
            key: "registration-field",
            link: "/registration-field",
            icon: "profile",
          },
          {
            item_group: false,
            name: "Tickets",
            key: "ticket",
            link: "/ticket",
            icon: "qrcode",
          }
        ]
      },
      {
        item_group: true,
        name: "Participants",
        key: "participant",
        icon: "team",
        items: [
          {
            item_group: false,
            name: "Registration",
            key: "registration",
            link: "/registration",
            icon: "user-add",
          },
        ]
      },
      {
        item_group: true,
        name: "Raffle",
        key: "Raffle",
        icon: "gift",
        items: [
          {
            item_group: false,
            name: "Raffle Prizes",
            key: "raffle-prizes",
            link: "/raffle-prizes",
            icon: "star",
          },
          {
            item_group: false,
            name: "Collection",
            key: "raffle-collection",
            link: "/raffle-collection",
            icon: "gold",
          },
          {
            item_group: false,
            name: "Controller",
            key: "raffle-controller",
            link: "/raffle-controller",
            icon: "control",
          },
        ]
      },
      {
        item_group: true,
        name: "Reports",
        key: "Reports",
        icon: "project",
        items: [
          {
            item_group: false,
            name: "Attendance",
            key: "attendance",
            link: "/attendance",
            icon: "schedule",
          },
          {
            item_group: false,
            name: "Audit Log",
            key: "audit-log",
            link: "/audit-log",
            icon: "audit",
          },
          {
            item_group: false,
            name: "Email Log",
            key: "email-log",
            link: "/email-log",
            icon: "idcard",
          },
          {
            item_group: false,
            name: "Passport Claims",
            key: "passport-claims",
            link: "/passport-claims",
            icon: "unordered-list",
          },
          {
            item_group: false,
            name: "Payments",
            key: "payments",
            link: "/payments",
            icon: "unordered-list",
          },
          // {
          //   item_group: false,
          //   name: "Raffle Winners",
          //   key: "raffle-winners",
          //   link: "/raffle-winners",
          //   icon: "trophy",
          // },
          {
            item_group: false,
            name: "Sales Report",
            key: "sales-report",
            link: "/sales-report",
            icon: "area-chart",
          },
        ]
      },
      {
        item_group: true,
        name: "System Settings",
        key: "system-settings",
        icon: "setting",
        items: [
          {
            item_group: false,
            name: "Users",
            key: "users",
            link: "/user",
            icon: "user",
          },
          {
            item_group: false,
            name: "User Roles",
            key: "user-roles",
            link: "/user-role",
            icon: "team",
          },
        ]
      }
    ]
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  componentDidMount() {
    const { sider } = this.state;
    const currentPath = this.props.location.pathname;

    if (getMenu() && currentPath === '/admin' && getMenu().link === "/") {
      sider.forEach(row => {
        if (row.link === getMenu().link === "/") {
          setMenu(row);
        }
      });
    } else if (getMenu() && currentPath !== getMenu().link) {
      sider.forEach(row => {
        if (row.items) {
          row.items.forEach(subRow => {
            if (subRow.link == currentPath) {
              setMenu(subRow);
            }
          });
        } else {
          if (row.link == currentPath) {
            setMenu(row);
          }
        }
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    
  }

  handleMenuClick = (menu) => {
    setMenu(menu);
  }

  render() {
    const user = JSON.parse(getUser());
    const { sider, } = this.state;
    const { selectedEvent } = this.props;

    const path = window.location.pathname

    if (path != "/login" && !user) {
      window.location.href = "/login";
      return;
    }

    return (
      <Layout.Sider className="sub-layout_sider" theme="light">
        <Logo />
        {selectedEvent && selectedEvent.id ?
          <Menu
            style={{ height: "100%", padding: "20px 0 20px 0" }}
            mode="inline"
            defaultSelectedKeys={[path.replace('/', '')]}
          >
            {sider.map(item => {
              if (item && item.items) {
                let hasChildPermission = false;

                item.items.forEach(sub => {
                  if (checkPermission(sub.key, "view")) {
                    hasChildPermission = true;
                  }
                });

                if (hasChildPermission) {
                  return (
                    <Menu.ItemGroup
                      key={item.key}
                      title={
                        <span style={{ color: "rgba(0, 0, 0, 0.65)" }}>
                          <Icon type={item.icon} style={{ marginRight: "10px" }} />
                          <span>{item.name}</span>
                        </span>
                      }>
                      {item.items.map(subItem => {
                        if (checkPermission(subItem.key, "view")) {
                          return (
                            <Menu.Item key={subItem.key}>
                              <Link to={subItem.link} onClick={() => this.handleMenuClick(subItem)}>
                                <Icon type={subItem.icon} />
                                {subItem.name}
                              </Link>
                            </Menu.Item>
                          );
                        } else {
                          return ('');
                        }
                      })}
                    </Menu.ItemGroup>
                  );
                } else {
                  return ('');
                }
              } else {
                if (checkPermission(item.key, "view")) {
                  return (
                    <Menu.Item key={item.key}>
                      <Link to={item.link} onClick={() => this.handleMenuClick(item)}>
                        <Icon type={item.icon} />
                        {item.name}
                      </Link>
                    </Menu.Item>
                  );
                } else {
                  return ('');
                }
              }
            })}
          </Menu>
          : ''
        }
      </Layout.Sider>
    );
  }
}

const LayoutSiderComponent = Form.create({ name: "layout-sider" })(LayoutSider);

export default LayoutSiderComponent;
