import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "antd";
import Page from "./Page";

class AdminCard extends Component {
  render() {
    return <Page {...this.props} />;
  }
}

const AdminForm = Form.create({ name: "admin_form" })(AdminCard);

function mapStateToProps(state) {
  return {
    isLoading: state.profile.isLoading,
    usersData: state.profile.userData,
  };
}

function mapDispatchToProps(dispatch) {
  return {

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminForm);
